<script>
	import {
		mapGetters,
		mapMutations,
		mapActions
	} from 'vuex'
	export default {
		globalData: {
			music: null,
			musicCurrent: 0
		},
		onLaunch: function(options) {
			const path = options.path
			const query = options.query
			// 渠道
			const scene = options.scene
			const that = this
			// console.log('App Launch')
			uni.onNetworkStatusChange(function(res) {
				that.setNetwork(res)
			})
			//App2.6.5+ 仅iOS
			// #ifdef APP-PLUS
			let theme = that.theme
			theme = theme || plus.navigator.getUIStyle();
			that.setThemeStyle(theme)
			// #endif

			// #ifdef APP-PLUS || MP-WEIXIN
			uni.onThemeChange(function(res) {
				that.setThemeStyle(res.theme)
			});
			// #endif

			// #ifndef APP-PLUS || H5
			if (uni.canIUse('getUpdateManager')) {
				const updateManager = uni.getUpdateManager();
				updateManager.onCheckForUpdate((res) => {
					if (res.hasUpdate) {
						updateManager.onUpdateReady(() => {
							uni.message.alert('更新提示', '发现新版本，为了获得更好的体验，建议立即更新', (res) => {
								updateManager.applyUpdate();
							});
						});
						updateManager.onUpdateFailed(() => {
							uni.message.alert('更新失败', '新版本更新失败，请稍后再试或删除小程序重新搜索打开',
								(res) => {});
						});
					}
				});
			}
			// #endif

			console.log('APP', query)
			// if (query._worker) this.setWorkerId(query._worker)
			// if (query._invite) this.setInviteId(query._invite)


			// console.log('mapGetters', mapGetters)
			this.systemInit()

			if (query.xcwl == 1) {
				uni.cache.clearCore(1)
			} else {
				this.userTokenInit()
			}
		},
		watch: {
			network: {
				handler: function(info) {
					if (!info.isConnected) {
						uni.message.tips('网络已断开，请检查当前环境');
					} else if (info.networkType == '3g') {
						uni.message.tips('网络不太稳定');
					} else if (info.networkType == '2g') {
						uni.message.tips('当前信号很弱，请注意使用环境');
					}
				},
				deep: true,
				// immediate: true
			},
			userInfo: {
				handler: function(newVal, oldVal) {
					console.log('watch userInfo', newVal)
					if (newVal && newVal.openid) {
						this.setOpenid(newVal.openid)
					}
				},
				deep: true,
				immediate: true
			},
		},
		computed: {
			...mapGetters([
				'theme',
				'network',
			])
		},
		onShow: function() {
			// console.log(uni.$fui.toast('提示~'))
		},
		onHide: function() {
			console.log('App Hide')
		},
		onThemeChange: function({
			theme
		}) {
			this.setThemeStyle(theme)
		},
		onPageNotFound: function(e) {
			console.log('页面未找到', e)
		},
		methods: {
			...mapMutations(['setTheme', 'setNetwork', 'systemInit', 'setWorkerId', 'setInviteId']),
			...mapActions(['userTokenInit']),
			setThemeStyle(theme) {
				// #ifdef APP-PLUS
				if (theme === 'dark') {
					plus.nativeUI.setUIStyle('dark');
				} else {
					plus.nativeUI.setUIStyle('light');
				}
				// #endif
				this.setTheme(theme)
			},

		}
	}
</script>

<style lang="scss">
	// page {
	// background-color: var(--sys-bg-color);
	// }
	@import '@/uni_modules/uv-ui-tools/index.scss';

	/* 加载图标字体 - 条件编译模式 */
	@import '@/static/iconfont/iconfont.css';
	// 暂时不用
	@import '@/static/css/global.scss';

	@import '@/static/css/theme.scss';

	@import '@/static/css/style.scss';
</style>