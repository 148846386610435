import cache from "@/xinda/libs/cache.js"
import http from "@/xinda/libs/request.js"

import config from "@/globalConfig.js"

// 需要统一 uni.createInnerAudioContext,否则可能重复播放,组件失效等问题
const state = {
	// 音乐播放器组件
	musicContext: null,
	// 音乐播放器状态
	musicStop: true,
	musicPause: true,
	musicPlay: false,
}
const getters = {
	//对外参数
	musicContext: (state) => state.musicContext,
	musicStop: (state) => state.musicStop,
	musicPause: (state) => state.musicPause,
	musicPlay: (state) => state.musicPlay
}
const mutations = {
	//修改值方法
	//this.$store.commit('setMusicContext',object);
	setMusicContext(state, data) {
		data = data === undefined ? null : data
		state.musicContext = data
	},
	setMusicStop(state, data) {
		data = data === undefined ? true : data
		state.musicStop = data
	},
	setMusicPause(state, data) {
		data = data === undefined ? true : data
		state.musicPause = data
	},
	setMusicPlay(state, data) {
		data = data === undefined ? true : data
		state.musicPlay = data
	},
}
const actions = {
	// this.$store.dispatch('refreshUserInfo')
	// 激活token
}

export default {
	state,
	getters,
	mutations,
	actions,
}