/**
 * 网络请求
 * 文档地址：https://doc.firstui.cn/
 **/

import base from './common.js'
import createTaskStore from './taskStore.js'

import $store from "@/store"

const store = createTaskStore()

class FIRSTUI_INNER {
	constructor(initConfig = {}) {
		this.initConfig = initConfig
		this.request = []
		this.response = []
		this.cancelToken = {}
		this.dispatchRequest = this.dispatchRequest.bind(this)
		this.loading = false
	}
	dispatchRequest(config = {}) {
		let params = base.mergeConfig(this.initConfig, config)
		const task = {
			url: params.url,
			method: params.method,
			keys: params.keys,
			data: params.data
		}
		if (params.prevent && store.requestTaskStore(task)) {
			return new Promise((resolve, reject) => {
				reject({
					statusCode: -9998,
					errMsg: 'request:prevented'
				})
			});
		}
		const arrayFormat = params.arrayFormat;
		let options = base.getOptions(params)
		if (options.method.toLocaleLowerCase() === 'get' && arrayFormat !== 'comma') {
			options = base.getParamsHandle(options, arrayFormat)
		}
		let promise = Promise.resolve(options);
		promise = promise.then(config => {
			if (params.showLoading && !this.loading) {
				base.showLoading(params.loadingText)
				this.loading = true
			}
			return new Promise((resolve, reject) => {
				// console.log(options)
				if (options.method == 'UPLOAD') {
					const header = options.header
					// 上传时，不能自定义Content-Type 否则会导致上传失败
					if (header != undefined) {
						Object.keys(header).forEach((headerKey) => {
							if (headerKey.toLocaleLowerCase() == 'content-type' || headerKey
								.toLocaleLowerCase() == 'content-type') {
								delete header[headerKey]
							}
						})
					}
					// console.log('options', options)

					// 上传请求
					const uploadOptions = {
						url: options.url,
						name: options.name,
						header: options.header,
					}
					if (options.data != undefined && Object.keys(options.data).length > 0)
						uploadOptions.formData = options.data

					if (options.files != undefined) {
						uploadOptions.files = options.files
					} else {
						if (options.fileType != undefined) uploadOptions.fileType = options.fileType
						if (options.filePath != undefined) uploadOptions.filePath = options.filePath
					}

					// console.log('uploadOptions', uploadOptions)
					let requestTask = uni.uploadFile({
						...uploadOptions,
						success: (res) => {
							if (params.showLoading && this.loading) {
								uni.hideLoading()
								this.loading = false
							}
							if (res.data != undefined) res.data = JSON.parse(res.data)
							resolve(params.brief ? res.data : res)
						},
						fail: (err) => {
							if (params.showLoading && this.loading) {
								uni.hideLoading()
								this.loading = false
							}

							if (params.errorMsg) {
								base.toast(params.errorMsg)
							}
							reject(err)
						},
						complete: () => {
							store.removeRequestTask(task)
							if (params.cancelToken && this.cancelToken[params
									.cancelToken]) {
								delete this.cancelToken[params.cancelToken]
							}
						}
					})
				} else {
					// 普通请求
					let requestTask = uni.request({
						...options,
						success: (res) => {
							if (params.showLoading && this.loading) {
								uni.hideLoading()
								this.loading = false
							}
							resolve(params.brief ? res.data : res)
						},
						fail: (err) => {
							if (params.showLoading && this.loading) {
								uni.hideLoading()
								this.loading = false
							}

							if (params.errorMsg) {
								base.toast(params.errorMsg)
							}
							reject(err)
						},
						complete: () => {
							store.removeRequestTask(task)
							if (params.cancelToken && this.cancelToken[params
									.cancelToken]) {
								delete this.cancelToken[params.cancelToken]
							}
						}
					})
				}
				if (params.cancelToken) {
					this.cancelToken[params.cancelToken] = requestTask;
				}
				if (params.timeout && typeof params.timeout === 'number' && params.timeout > 2000) {
					setTimeout(() => {
						try {
							store.removeRequestTask(task)
							if (params.cancelToken) {
								delete this.cancelToken[params.cancelToken]
							}
							requestTask.abort();
						} catch (e) {}
						resolve({
							statusCode: -9999,
							errMsg: 'request:cancelled'
						});
					}, params.timeout)
				}
			});
		})

		return promise
	}
}


const inner = new FIRSTUI_INNER(base.config())

const http = {
	interceptors: {
		request: {
			use: (fulfilled, rejected) => {
				inner.request.push({
					fulfilled,
					rejected
				})
			},
			eject: (name) => {
				if (inner.request[name]) {
					inner.request[name] = null;
				}
			}
		},
		response: {
			use: (fulfilled, rejected) => {
				inner.response.push({
					fulfilled,
					rejected
				})
			},
			eject: (name) => {
				if (inner.response[name]) {
					inner.response[name] = null;
				}
			}
		}
	},
	create(config) {
		inner.initConfig = base.mergeConfig(base.config(), config, true);
	},
	get(url, data = null, config = {}) {
		config.method = 'GET'
		config.url = url || config.url || ''
		if (data != null) config.data = data
		// console.log('$store.getters.mchId', $store.getters)
		// console.log($store.getters.workerId)
		if ($store.getters.mchId && (data == null || data._mch == undefined))
			config.data._mch = $store.getters.mchId
		if ($store.getters.workerId && (data == null || data._worker == undefined))
			config.data._worker = $store.getters.workerId
		if ($store.getters.inviteId && (data == null || data._invite == undefined))
			config.data._invite = $store.getters.inviteId

		return http.request(config)
	},
	post(url, data = null, config = {}) {
		config.method = 'POST'
		config.url = url || config.url || ''
		if (data != null) config.data = data

		// console.log('getters', $store.getters)

		// console.log('workerId', $store.getters.workerId)
		// console.log('inviteId', $store.getters.inviteId)

		// console.log('post', data)

		if ($store.getters.mchId && (data == null || data._mch == undefined))
			config.data._mch = $store.getters.mchId
		if ($store.getters.workerId && (data == null || data._worker == undefined))
			config.data._worker = $store.getters.workerId
		if ($store.getters.inviteId && (data == null || data._invite == undefined))
			config.data._invite = $store.getters.inviteId

		// console.log('post', data)

		return http.request(config)
	},
	// 单文件上传，批量上传小程序不兼容，所以如果要批量上传，循环调用本接口
	upload(url, filePath, data = null, fileType, filedName = 'xcwlupload', config = {}) {
		config.method = 'UPLOAD'
		config.url = url || config.url || ''
		if (data != null) config.data = data

		// 文件类型，image/video/audio，仅支付宝小程序，且必填。
		if (fileType != undefined) config.fileType = fileType

		// 要上传文件资源的路径。
		if (filePath != undefined) config.filePath = filePath

		// 上传表单名字
		if (filedName != undefined) config.name = filedName

		// Object.assign(config, fileData)
		// console.log('config', config)
		return http.request(config)
	},

	all(requests) {
		return Promise.all(requests)
	},
	request(config = {}) {
		let chain = [inner.dispatchRequest, undefined];
		let promise = Promise.resolve(config);

		inner.request.forEach(interceptor => {
			chain.unshift(interceptor.fulfilled, interceptor.rejected);
		});

		inner.response.forEach(interceptor => {
			chain.push(interceptor.fulfilled, interceptor.rejected);
		});

		while (chain.length) {
			promise = promise.then(chain.shift(), chain.shift());
		}

		return promise;
	},
	abort(cancelToken) {
		if (!cancelToken) return;
		try {
			if (inner.cancelToken[cancelToken]) {
				inner.cancelToken[cancelToken].abort()
				delete inner.cancelToken[cancelToken]
				// console.log('request:cancelled')
			}
		} catch (e) {}
	}
}
export default http