const modules = {}


// #ifndef VUE3
const filesV2 = require.context('./_modules', false, /\.js$/)
filesV2.keys().forEach((key) => {
	modules[key.replace(/(_modules|\/|\.|js)/g, '')] = {
		...filesV2(key).default,
		namespaced: true,
	}
})

// console.log(filesV2)

// #endif

// #ifdef VUE3
// 需要强制加载所有，不然读不出来
const filesV3 = import.meta.glob('./_modules/*.js', {
	eager: true,
	import: 'default'
});
for (let key in filesV3) {
	modules[key.replace(/(_modules|\/|\.|js)/g, '')] = {
		...filesV3[key],
		// namespaced: true,
	}
}

// console.log(filesV3)
// #endif



// console.log(modules)

let exportStore = null;
// #ifndef VUE3
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
exportStore = new Vuex.Store({
	modules,
})
// #endif

// #ifdef VUE3
import {
	createStore
} from 'vuex'

exportStore = createStore({
	modules,
})
// #endif

export default exportStore