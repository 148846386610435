import globalConfig from '@/globalConfig.js';
import cache from "@/xinda/libs/cache.js"

import store from "@/store"

export default {
	previewImage(current, list) {
		uni.previewImage({
			current,
			urls: list,
		})
	},
	//判断是否在微信中
	isWechat: function() {
		var ua = window.navigator.userAgent.toLowerCase()
		if (ua.match(/micromessenger/i) == "micromessenger") {
			return true
		} else {
			return false
		}
	},
	event(event, data) {
		data = data == undefined ? {} : data
		uni.$emit(event, data)
	},

	phoneMask(phone) {
		if (!phone) return ""

		if (phone.length === 11) {
			return `${phone.substring(0, 3)}****${phone.substring(7, 11)}`
		}
		return ""

		// return phone
	},


	strMask(str) {
		if (!str) return ""
		return `${str.substring(0, 4)}****${phone.substring(str.length-3)}`
	},

	pagePath() {
		const pages = getCurrentPages()
		const page = pages[pages.length - 1].$page
		return {
			path: page.path,
			options: page.options,
			openType: page.openType,
			fullPath: page.fullPath,
		}
	},

	reLoad(path) {
		if (path == undefined) {
			path = "/"
			let pages = getCurrentPages(),
				prePage = false
			if (pages.length > 0) prePage = pages[pages.length - 1]
			if (prePage) {
				path += prePage.route + "?" + this.obj2url(prePage.query)
			}
		}
		uni.reLaunch({
			url: path,
		})
	},

	setTitle(text) {
		uni.setNavigationBarTitle({
			title: text,
		})
	},
	getDate(type) {
		const date = new Date()
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		if (type === "start") {
			year -= 60
		} else if (type === "end") {
			year += 2
		}
		month = month > 9 ? month : `0${month}`
		day = day > 9 ? day : `0${day}`
		return `${year}-${month}-${day}`
	},

	// chkLogin，0=不检测登录，1=检测登录，2=检测登录&检测绑定手机号
	// 'navigateTo', 'redirectTo', 'reLaunch', 'switchTab'
	routeTo(path, noBack = 0, chkLogin = 0) {
		if (!path) return
		if (chkLogin > 0) {
			let isLogin = store.getters.isLogin
			let hasMobile = store.getters.hasMobile
			if (!isLogin) {
				if (wechat.isWechat()) {
					wechat.goWechatCode("auto")
				} else {
					this.toLogin()
				}
				return false
			}
			if (chkLogin === 2) {
				if (!hasMobile) {
					this.toBindMobile()
					return false
				}
			}
		}

		if (noBack == 1) {
			uni.redirectTo({
				url: path,
				error(res) {
					console.log("routeTo redirectTo error", res)
				},
			})
		} else {
			if (globalConfig.tabBar.indexOf(path) >= 0) {
				uni.switchTab({
					url: path,
					error(res) {
						console.log("toBack switchTab error", res)
					},
				})
				//   console.log("uni.reLaunch")
			} else {
				uni.navigateTo({
					url: path,
					animationType: "pop-in",
					animationDuration: 200,
					error(res) {
						console.log("navigateTo navigateTo error", res)
					},
				})
			}
		}
	},
	toBack() {
		// #ifdef H5
		let backUrl = cache.get("backRouteCache")
		// console.log(backUrl)
		if (backUrl) {
			cache.remove("backRouteCache")
			window.location.href = backUrl
		} else {
			// console.log(window.history)
			if (window.history.length > 1) {
				window.history.back()
			} else {
				uni.switchTab({
					url: globalConfig.INDEXPAGE,
				})
			}
		}
		// #endif

		// #ifndef H5
		let backData = cache.get("toBackData"),
			that = this
		// console.log("backData", backData)
		if (backData) {
			cache.remove("toBackData")
			let pages = getCurrentPages()
			//   console.log("backData pages", pages)


			if (pages.length > 1) {
				//函数用于获取当前页面栈的实例，以数组形式按栈的顺序给出，第一个元素为首页，最后一个元素为当前页面
				var num = pages.length
				//当前页面栈总数
				var backnum
				//需要返回的页数
				for (var i = 0; i < num; i++) {
					//循环找到指定页面路由所在的页数
					if (pages[i].route == backData) {
						//'pages/mypage/mypage'替换成A页面的路由地址
						backnum = num - i - 1
						//计算返回的层数，总数-指定页面页数-1
					}
				}
				uni.navigateBack({
					delta: backnum,
					//返回的页面数，如果 delta 大于现有页面数，则返回到首页。
					animationType: "pop-out",
					animationDuration: 200,
					error(res) {
						console.log("toBack navigateBack error", res)
					},
				})
			} else {
				backData = `/${backData}`.replace("//", "/")

				// console.log("backData==", backData)
				// console.log("backData==", tabBar.indexOf(backData))
				if (globalConfig.tabBar.indexOf(backData) >= 0) {
					console.log("uni.reLaunch backData")
					uni.switchTab({
						url: backData,
						error(res) {
							console.log("toBack switchTab error", res)
						},
					})
					//   console.log("uni.reLaunch")
				} else {
					uni.redirectTo({
						url: backData,
						error(res) {
							console.log("toBack redirectTo error", res)
						},
					})
				}
			}
			// page.reLoad()
		} else {
			console.log("toBack", getCurrentPages())
			let pages = getCurrentPages()
			if (pages.length > 1) {
				uni.navigateBack({
					delta: 1,
					animationType: "pop-out",
					animationDuration: 200,
				})
			} else {
				uni.switchTab({
					url: globalConfig.INDEXPAGE,
				})
			}
		}
		// #endif
	},

	toHome() {
		cache.remove("toBackData")
		cache.remove("backRouteCache")
		uni.switchTab({
			url: globalConfig.INDEXPAGE,
		})
	},

	setGlobal(key, data) {
		getApp().globalData[key] = data
	},
	getGlobal(key) {
		return getApp().globalData[key]
	},

	call(phoneNumber) {
		uni.makePhoneCall({
			phoneNumber: phoneNumber,
			success: function() {},
			fail: function() {}
		})
		return true
	},
	toUser() {
		uni.switchTab({
			url: globalConfig.USERINDEX,
		})
	},
	toLink(url) {
		let that = this
		// #ifdef APP-PLUS
		plus.runtime.openURL(url, (res) => {
			console.log(res)
		})
		// #endif
		// #ifdef H5
		window.location.href = url
		// #endif
	},
	toTel(number) {
		uni.makePhoneCall({
			phoneNumber: number,
		})
	},
	toLogin() {
		this.setBackPage(1)
		uni.navigateTo({
			url: globalConfig.LOGINPAGE,
		})
	},
	toBindMobile() {
		this.setBackPage(1)
		uni.navigateTo({
			url: "/user/bindMobile",
		})
	},

	// 获取完整页面，并且替换指定参数 H5专用，可以过滤指定参数，含有http://
	getFullUrl(params = null) {
		const paramKey = params != null && typeof params == 'object' ? Object.keys(params) : [];
		if (paramKey.length > 0) {
			const url = new URL(window.location.href);

			for (let index in paramKey) {
				const key = paramKey[index]

				if (params[key] == null) {
					url.searchParams.delete(key)
				} else {
					url.searchParams.set(key, params[key])
				}
			}

			return url.href
		} else {
			return window.location.href
		}
	},
	// 获取页面path，并且可以过滤指定参数，或者添加指定参数,不含域名等信息
	getFullPath(params = null) {
		const paramKey = params != null && typeof params == 'object' ? Object.keys(params) : [];

		let pages = getCurrentPages()
		let currPage = null

		// console.log('paramKey', paramKey)
		if (pages.length) {
			currPage = pages[pages.length - 1]

			const _page = currPage.$page
			const query = _page.options


			for (let index in paramKey) {
				const key = paramKey[index]

				if (params[key] == null) {
					if (query[key]) delete query[key]
				} else {
					query[key] = params[key]
				}
			}

			return `${_page.path}?${this.objToQuery(query)}`
		}
		return ""
	},
	// 获取当前页面PATH
	getPagePath() {
		let pages = getCurrentPages()
		let currPage = null

		if (pages.length) {
			currPage = pages[pages.length - 1]
			return currPage.$page.path
		}
		return ""
	},
	// 获取当前页面对象
	getPageRoute() {
		let pages = getCurrentPages()
		let currPage = null
		if (pages.length) {
			currPage = pages[pages.length - 1]
			return currPage.$page
		}
		return null
	},
	// 检查当前页面,如何跳转, 返回：'navigateTo', 'redirectTo', 'reLaunch', 'switchTab'
	getHowTo() {
		let pages = getCurrentPages()
		let currPage = null

		if (pages.length) {
			currPage = pages[pages.length - 1]
			return currPage.$page.openType
		}
		return ""
	},

	// 将对象转为URL query
	objToQuery(obj) {
		let queryArr = [];
		Object.keys(obj).forEach((key, index) => {
			queryArr.push(`${key}=${encodeURIComponent(obj[key])}`)
		})
		return queryArr.join('&');
	},

	isMobileNo(str) {
		var myreg = /^[1][3-9][0-9]{9}$/
		if (!myreg.test(str)) {
			return false
		} else {
			return true
		}
	},
	isTel(str) {
		var myreg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
		if (!myreg.test(str)) {
			return false
		} else {
			return true
		}
	},
	isPhone(str) {
		var mobileReg = /^[1][3-9][0-9]{9}$/
		if (!mobileReg.test(str)) {
			var telReg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
			if (!telReg.test(str)) {
				return false
			} else {
				return true
			}
		} else {
			return true
		}
	},
	today() {
		var myDate = new Date()
		var year = myDate.getFullYear() //年
		var month = myDate.getMonth() + 1 //月
		var day = myDate.getDate() //日
		var days = myDate.getDay()
		switch (days) {
			case 1:
				days = "星期一"
				break
			case 2:
				days = "星期二"
				break
			case 3:
				days = "星期三"
				break
			case 4:
				days = "星期四"
				break
			case 5:
				days = "星期五"
				break
			case 6:
				days = "星期六"
				break
			case 0:
				days = "星期日"
				break
		}
		return {
			date: year + "年" + month + "月" + day + "日",
			week: days,
		}
	},
	dateFormat(fmt, date) {
		// dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss.S')
		date = new Date(date)
		var a = ["日", "一", "二", "三", "四", "五", "六"]
		var o = {
			"M+": date.getMonth() + 1, // 月份
			"d+": date.getDate(), // 日
			"h+": date.getHours(), // 小时
			"m+": date.getMinutes(), // 分
			"s+": date.getSeconds(), // 秒
			"q+": Math.floor((date.getMonth() + 3) / 3), // 季度
			S: date.getMilliseconds(), // 毫秒
			w: date.getDay(), // 周
			W: a[date.getDay()], // 大写周
			T: "T",
		}
		if (/(y+)/.test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				(date.getFullYear() + "").substr(4 - RegExp.$1.length)
			)
		}
		for (var k in o) {
			if (new RegExp("(" + k + ")").test(fmt)) {
				fmt = fmt.replace(
					RegExp.$1,
					RegExp.$1.length === 1 ?
					o[k] :
					("00" + o[k]).substr(("" + o[k]).length)
				)
			}
		}
		return fmt
	},
	//写入返回页面
	setBackPage(current = 1) {
		//current=1时，是把当前页面作为缓存，写入返回页面
		// cache.clear('userInfo');
		// cache.clear('wechatUser');

		// #ifdef H5
		let url = ""
		if (current === 1) {
			url = window.location.href
		} else {
			url = document.referrer
			url = url ? url : globalConfig.INDEXPAGE
		}
		console.log(url)

		// 去除掉URL中的token
		let urlObj = new URL(url)
		let query = urlObj.query

		if (query != null) {
			url = urlObj.href.replace(urlObj.search, "")
			let query_arr = query.split("&"),
				new_arr = []
			query_arr.forEach((item, index) => {
				if (item.search(/token=/) == -1) {
					new_arr.push(item)
				}
			})

			if (new_arr.length > 0) {
				url = url + "?" + new_arr.join("&")
			}
		}
		// 去除掉URL中的token
		console.log('backRouteCache', url)
		cache.set("backRouteCache", url)
		// #endif

		// #ifndef H5
		let pages = getCurrentPages(),
			prePage = false

		if (current === 1) {
			if (pages.length > 0) prePage = pages[pages.length - 1]
		} else {
			if (pages.length > 1) prePage = pages[pages.length - 2]
		}
		if (prePage) {
			console.log("currentWebview", prePage.route)
			cache.set("toBackData", prePage.route)
		}
		// #endif
	},

	url2obj(str) {
		let param_arr = str.split("&"),
			param = {}
		for (let i = 0; i < param_arr.length; i++) {
			let res = param_arr[i].split("=")
			param[res[0]] = res[1]
		}
		return param
	},
	obj2url(json) {
		Object.keys(json)
			.map(function(key) {
				return encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
			})
			.join("&")
	},

	guid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function(c) {
				var r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8
				return v.toString(16)
			}
		)
	},

	createIframe() {
		let iframe
		return function() {
			if (iframe) {
				return iframe
			} else {
				iframe = document.createElement("iframe")
				iframe.style.display = "none"
				document.body.appendChild(iframe)
				return iframe
			}
		}
	},

	uploadFile(
		filePath,
		config = {
			url: "upload/index/index",
			field: "xcwlupload",
			type: "image",
		}
	) {
		console.log(filePath)
		let authKey = store.getters.authKey
		let uploadServer = globalConfig.apiUrl + config.url
		let field = config.field ? config.field : "xcwlupload"
		let type = config.type ? config.type : "image"

		return new Promise((resolve, reject) => {
			let a = uni.uploadFile({
				url: uploadServer,
				filePath: filePath,
				name: field,
				formData: {
					authkey: authKey,
					type: type,
				},
				success: (res) => {
					resolve(JSON.parse(res.data))
				},
				fail() {
					resolve({
						code: 555,
						msg: "服务器响应错误",
					})
				},
			})
		})
	},
}