import cache from "@/xinda/libs/cache.js"
import http from "@/xinda/libs/request.js"

import config from "@/globalConfig.js"

const state = {
	wxOpenid: cache.get("wxOpenid"), //小程序中这个不能取默认值，否则会出问题
	wxappSessionKey: ""
}
const getters = {
	//对外参数
	wxOpenid: (state) => state.wxOpenid,
	wxappSessionKey: (state) => state.wxappSessionKey,
}
const mutations = {
	setOpenid(state, data) {
		data = data === undefined ? "" : data
		state.wxOpenid = data

		cache.set("wxOpenid", data, config.authTime)
	},
	setWxappSessionKey(state, data) {
		data = data === undefined ? "" : data
		state.wxappSessionKey = data
	},
}
const actions = {
	wxappLogin({
		commit,
		getters
	}) {
		if (getters.wxOpenid) return true;
		uni.login({
			success(res) {
				// console.log(res)
				if (res.code) {
					//发起网络请求
					http
						.get("third/wxapp/login", {
							code: res.code,
						})
						.then((res) => {
							// console.log(res)
							if (res.code == 0) {
								commit("setOpenid", res.data.openid || '')
								commit("setWxappSessionKey", res.data.session_key || '')
							}
						})

				} else {
					console.log('登录失败！' + res.errMsg)
				}
			}
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}