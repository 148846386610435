import cache from "@/xinda/libs/cache.js"
import http from "@/xinda/libs/request.js"

import config from "@/globalConfig.js"

const state = {
	//变量定义
	location: (cache.get("user_location")) || {
		key: "",
		name: "全部",
	},
	authKey: cache.get("authKey") || '',
	userInfo: cache.get("userInfo") || false,

	clientID: "",
	sessionID: "",

	msgCount: 0,

	// 是否回写缓存
	isCache: true,
}
const getters = {
	//对外参数
	location: (state) => state.location,

	sessionID: (state) => state.sessionID,
	clientID: (state) => state.clientID,

	authKey: (state) => state.authKey,

	msgCount: (state) => state.msgCount,

	userInfo: (state) => {
		return state.userInfo || false
	},
	isLogin: (state) => {
		return !!state.authKey
	},
	hasMobile: (state) => {
		return state.userInfo ? (state.userInfo.mobile ? true : false) : false
	},

	isCache: (state) => {
		return state.isCache || true
	},
}
const mutations = {
	//修改值方法
	//this.$store.commit('authKey','keyValue');
	setLocation(state, data) {
		state.location = data === undefined ? {
				key: "",
				name: "全部",
			} :
			data
		cache.set("user_location", data)
	},

	setAuthKey(state, data) {
		data = data === undefined ? "" : data
		state.authKey = data
		if (!data) {
			cache.remove("authKey")
		}
		// console.log('data0', data)
		// if (state.isCache) {
		cache.set("authKey", data, config.authTime)
		// }
	},
	setUserInfo(state, data) {
		data = data === undefined ? false : data
		// if (data != false) {
		// 	if (data.hasOwnProperty('jiuzhenren')) {
		// 		if (data.jiuzhenren) {
		// 			state.huanzhe = data.jiuzhenren
		// 		}
		// 		delete data.jiuzhenren
		// 	}
		// }
		cache.set("userInfo", data, config.authTime)
		state.userInfo = data
	},
	setLogout(state) {
		state.authKey = ""
		state.userInfo = false
		cache.remove("authKey")
	},

	setClientID(state, data) {
		let _data = data === undefined ? "" : data
		state.clientID = _data

		if (!_data) {
			cache.remove("clientID")
		}
		if (state.isCache) {
			cache.set("clientID", _data)
		}
	},

	setSessionID(state, data) {
		let _data = data === undefined ? "" : data

		state.sessionID = _data
		if (!_data) {
			cache.remove("sessionID")
		}
		if (state.isCache) {
			cache.set("sessionID", _data, 3600)
		}
	},

	setIsCache(state, data) {
		state.isCache = data == true
	},
}
const actions = {
	// this.$store.dispatch('refreshUserInfo')
	// 激活token
	userTokenInit({
		getters,
		commit,
		dispatch
	}) {
		commit('setIsCache', false);

		let _sessionID = cache.get("sessionID")
		_sessionID = _sessionID ? _sessionID : ""
		commit("setSessionID", _sessionID)

		let _clientID = cache.get("clientID")
		_clientID = _clientID ? _clientID : ""
		commit("setClientID", _clientID)

		let _authKey = cache.get("authKey")
		_authKey = _authKey ? _authKey : ""
		commit("setAuthKey", _authKey)

		if (_authKey) dispatch('userInfoInit')

		commit('setIsCache', true);
	},

	// 通过aukey 获取用户细腻些
	userInfoInit({
		getters,
		commit
	}, force = 0) {
		// console.log('userInfoInit', getters)
		let token = getters.authKey ? getters.authKey : cache.get("authKey")
		token = token ? token : ""
		if (!getters.authKey) commit("setAuthKey", token)

		// console.log('getters', token)
		if (token) {
			// 注意和后台对应
			http
				.post("user/login/check", {
					token: token,
				})
				.then((res) => {
					// console.log('getUserInfo', res)

					const {
						code,
						data
					} = res
					if (!code) {
						if (data) {
							commit("setUserInfo", data)
							if (data.openid) commit("setOpenid", data.openid)

						} else {
							commit("setLogout")
						}
					} else {
						commit("setLogout")
					}
				})
		} else {
			commit("setLogout")
		}
	},

	// 刷新用户信息
	refreshUserInfo({
		getters,
		commit
	}, force = 0) {
		let token = getters.authKey || cache.get("authKey")
		if (token) {
			// 注意和后台对应
			http
				.get("user/index/refresh", {
					force
				})
				.then((res) => {
					const {
						code,
						data
					} = res
					if (code == 0) {
						if (data) {
							commit("setUserInfo", data)
							if (data.openid) commit("setOpenid", data.openid)
						} else {
							commit("setLogout")
						}
					} else {
						commit("setLogout")
					}
				})
		} else {
			commit("setLogout")
		}
	},

	actionLogin({
		commit
	}, {
		authKey = "",
		userInfo = false
	}) {
		console.log(authKey, userInfo)
		if (!authKey || !userInfo) {
			commit("setLogout")
		} else {
			commit("setAuthKey", authKey)
			commit("setUserInfo", userInfo)
		}
	},

	actionLogout({
		getters,
		commit
	}) {
		let token = getters.authKey || cache.get("authKey")
		if (token) {
			// 注意和后台对应
			http
				.get("user/logout", {
					token: token,
				})
				.then((res) => {
					commit("setLogout")
				})
		} else {
			commit("setLogout")
		}
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}