import cache from "@/xinda/libs/cache.js"
import http from "@/xinda/libs/request.js"

import config from "@/globalConfig.js"

const state = {
	//邀请的工作人员ID
	workerId: cache.get('invite_worker_id') || '',
	// 推广邀请人ID
	inviteId: cache.get('invite_id') || '',
	// 门店ID
	mchId: cache.get('mch_id') || '',
	// 站点ID
	siteId: cache.get('site_id') || '',
}
const getters = {
	//对外参数
	workerId: (state) => state.workerId,
	inviteId: (state) => state.inviteId,
	mchId: (state) => state.mchId,
	siteId: (state) => state.siteId,
}
const mutations = {
	//修改值方法
	//this.$store.commit('setWorkerId','keyValue');
	setWorkerId(state, data) {
		data = data === undefined ? '' : data
		state.workerId = data
		cache.set("invite_worker_id", data)
	},
	setInviteId(state, data) {
		data = data === undefined ? '' : data
		state.inviteId = data
		cache.set("invite_id", data)
	},
	setMchId(state, data) {
		data = data === undefined ? '' : data
		state.mchId = data
		cache.set("mch_id", data)
	},
	setSiteId(state, data) {
		data = data === undefined ? '' : data
		state.siteId = data
		cache.set("site_id", data)
	}
}
const actions = {
	// this.$store.dispatch('refreshUserInfo')
	// 激活token
}

export default {
	state,
	getters,
	mutations,
	actions,
}