const message = {
	loading(msg) {
		if (msg === 1) {
			uni.hideLoading();
		} else {
			msg = msg == undefined ? '加载中...' : msg;
			uni.showLoading({
				title: msg,
				mask: true
			})

		}
	},
	tips(msg, callBack) {
		uni.showToast({
			title: msg,
			position: 'bottom',
			icon: 'none',
			success: () => {
				if (callBack !== undefined) {
					setTimeout(() => {
						callBack();
					}, 3000)
				}
			}
		});
	},
	alert(msg, callBack) {
		uni.showModal({
			title: '提示',
			content: msg,
			showCancel: false,
			success: function(res) {
				if (res.confirm) {
					if (callBack !== undefined) {
						callBack();
					}
				}
			}
		});
	},
	confirm(title, msg, successCallback, failCallback) {
		uni.showModal({
			title: title,
			content: msg,
			success: function(res) {
				if (res.confirm) {
					if (successCallback !== undefined) {
						successCallback();
					}
				} else if (res.cancel) {
					if (failCallback !== undefined) {
						failCallback();
					}
				}
			}
		});
	},
};

export default message