import config from "@/globalConfig.js"

const cache = {
	setRequest(cache_id, data, exptime) {
		const cache_data = {}

		if (data.code === 0 && exptime > 0) {
			cache_data.code = data.code
			cache_data.msg = data.msg
			cache_data.data = data.data
			this.set(`${config.cachePre}${cache_id}`, cache_data, exptime)
		}
	},
	set(cache_id, data, exptime = 0) {
		let that = this
		let time = new Date()

		if (cache_id == null || cache_id == undefined || data == undefined)
			return false

		if (data == null) {
			this.remove(data.cache_id)
			return true
		}

		// 将缓存ID写入缓存
		if (typeof data === "object") data.cache_id = cache_id

		// console.log(cache_id);
		const cache_data = {}

		cache_data.data = data
		cache_data.saveTime = time.getTime()

		if (exptime > 0) {
			cache_data.expireTime = time.getTime() + exptime * 1000
		}

		uni.setStorage({
			key: `${config.cachePre}${cache_id}`,
			data: cache_data,
			success() {
				return true
			},
			fail() {
				return false
			},
		})
	},
	setFull(cache_id, data) {
		let that = this
		if (typeof data == "object") data.cache_id = cache_id
		uni.setStorage({
			key: `${config.cachePre}${cache_id}`,
			data: data,
			success() {
				return true
			},
			fail() {
				return false
			},
		})
	},

	getFull(cache_id) {
		const cache_data = uni.getStorageSync(`${config.cachePre}${cache_id}`)
		if (cache_data) {
			return cache_data
		}
		return false
	},

	get(cache_id) {
		let that = this

		const cache_data = uni.getStorageSync(`${config.cachePre}${cache_id}`)
		if (cache_data) {
			if (that.checkDataTime(cache_data.expireTime)) {
				if (cache_data.data == false || cache_data.data == undefined) {
					that.remove(cache_id)
					return false
				}
				return cache_data.data
			}
			console.log("过期", cache_id)
			that.remove(cache_id)
			return false
		}
		return false
	},

	remove(cache_id, sync) {
		uni.removeStorageSync(`${config.cachePre}${cache_id}`)
	},

	clear(all) {
		let that = this
		let clearList = []
		uni.showModal({
			title: "提示",
			content: "确定要清除缓存吗",
			success(modelRes) {
				if (modelRes.confirm) {
					that.clearCore(all)
					uni.showToast({
						title: "清除成功",
						duration: 2000,
					})
				}
			},
		})
	},
	clearCore(all) {
		let that = this
		let clearList = []
		if (all === 1) {
			uni.clearStorageSync()
		} else {
			let authKey = that.getFull("authKey")
			let sessionID = that.getFull("sessionID")
			uni.clearStorageSync()
			authKey && that.setFull("authKey", authKey)
			sessionID && that.setFull("sessionID", sessionID)
		}
	},
	checkDataTime(expireTime) {
		let that = this
		let time = new Date()
		if (time.getTime() >= expireTime) {
			// console.log('过期了');
			return false
		}
		// console.log('没过期');
		return true
	},
}

export default cache