import http from './request-lib'

import globalConfig from '@/globalConfig.js';
import cache from "@/xinda/libs/cache.js"

import md5 from "@/xinda/libs/md5.js"
import des from "@/xinda/libs/des.js"

import store from "@/store"

//初始化请求配置项
http.create({
	host: globalConfig.apiUrl,
	arrayFormat: 'brackets',
	showLoading: false,
	header: {
		// 'content-type': 'application/x-www-form-urlencoded'
	}
})
//请求拦截
http.interceptors.request.use(config => {
	// console.log(config)
	//请求之前可在请求头中加入token等信息
	const clientID = store.getters.clientID || cache.get('clientID') || ''
	const sessionID = store.getters.sessionID || cache.get('sessionID') || ''
	const authKey = store.getters.authKey || cache.get('authKey') || ''

	if (config.header == undefined || !config.header) config.header = {}

	if (authKey) config.header['Authorization'] = `Bearer ${authKey}`

	if (clientID) config.header['clientID'] = clientID
	if (sessionID) config.header['sessionID'] = sessionID

	return config
})

//响应拦截
http.interceptors.response.use(response => {
	//TODO
	// console.log('response', response)
	const {
		data,
		header,
		statusCode,
		errMsg
	} = response
	if (statusCode == 200) {
		if (header != undefined) {
			const resHeader = {
				authKey: header.authKey || header.authkey || '',
				sessionID: header.sessionID || header.sessionid || '',
				clientID: header.clientID || header.clientid || '',
			}

			const storeHeader = {
				authKey: store.getters.authKey || cache.get('authKey') || '',
				clientID: store.getters.clientID || cache.get('clientID') || '',
			}

			// console.log('resHeader', resHeader)

			// console.log('storeHeader', storeHeader)

			// console.log(resHeader.authKey && resHeader.authKey != storeHeader.authKey)
			// sessionID每次都刷新
			if (resHeader.sessionID)
				store.commit('setSessionID', resHeader.sessionID)

			if (resHeader.authKey && resHeader.authKey != storeHeader.authKey)
				store.commit('setAuthKey', resHeader.authKey)

			if (resHeader.clientID && resHeader.clientID != storeHeader.clientID)
				store.commit('setClientID', resHeader.clientID)
		}
	} else {
		return Promise.reject(errMsg);
	}
	return Promise.resolve(data)
}, (err) => {
	//错误响应：断网、错误域名等
	return Promise.reject(err);
});

export default http