// 运行模式
const runMode = "final" //'dev'||'final'

const config = {
	// 是否启用调试模式
	debug: true,

	appName: "海棠企划",

	//传递参数是否加密
	ENCRYPT: false,
	// 加密KEY
	KEY: "xcwl.com",
	// 是否检查签名
	CHKSIGN: false,

	authTime: 7200,
	cachePre: "htqh_cache_",

	amapKey: "138bbf9803d3e7ad4c4a03882f1f0ac1",

	// 程序调用静态文件地址
	staticUrl: "https://cystatic.xcwl.net.cn",

	INDEXPAGE: "/index/index",
	LOGINPAGE: "/user/login",
	USERINDEX: "/user/index",

	notLoginPages: [
		"/index/index",
		"/notice/index",
		"/user/index",
		"/user/login",
		"/about/index",
		"/article/index",
		"/article/lists",
		"/article/zhuanjia",
	],

	// 用户检查跳转方式
	tabBar: ["/index/index", "/notice/index", "/user/index"],

	runMode: process.env.NODE_ENV === "development" || runMode == "dev" ?
		"dev" : "final",

	// 结尾不要 /
	// 如果是正式的的H5，那么直接使用 /api，然后反向代理/api，这样不用每次都编译代码。具体实现查看nginx.conf
	apiUrl: process.env.NODE_ENV === "development" || runMode == "dev" ?
		"https://app.htcqch.cn/api" : process.env.UNI_PLATFORM == "h5" ? "/api" : "https://app.htcqzx.cn/api",

	// 请求超时
	timeout: 30,
}

export default config