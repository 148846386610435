import cache from "@/xinda/libs/cache.js"

const state = {
	theme: uni.getStorageSync('theme') || 'light',
	network: {
		isConnected: true,
		networkType: '4g'
	},
	// 程序信息
	appInfo: {
		name: '',
		appId: '',
		version: '1.0.0',
		versionCode: '100',
		isDev: false,
		info: '',
		isMiniApp: false,
		client: '', //uniPlatform
	},
	deviceInfo: {
		// https://uniapp.dcloud.net.cn/api/system/info.html#%E7%B3%BB%E7%BB%9F%E4%BF%A1%E6%81%AF%E7%9A%84%E6%A6%82%E5%BF%B5
		id: '',
		type: '', // phone
		brand: '', // 
		model: '', // iPhone 14 Pro Max
		// 设备方向 竖屏 portrait、横屏 landscape
		orientation: '',
		pixel_ratio: '', // 3
		os: '',
		os_version: '',
		os_language: '',
		romName: ''
	},
	// 小程序自带胶囊
	sysCapsule: {
		bottom: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		width: 0,
	},
	// 左侧胶囊
	leftCapsule: {
		bottom: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		width: 0,
	},
	barHeight: {
		// 顶部状态栏
		statusBar: 0,
		// 标题高度
		titleBar: 0,
		navBar: 0,
		tabBar: 0,
		// 顶部状态栏+标题高度
		allBar: 0,

	},
	screen: {
		widthFull: 0,
		heightFull: 0,
		width: 0,
		height: 0,
		top: 0,
		bottom: 0,
	},
	// 页面安全区域
	pageSafeArea: {}
}
const getters = {
	//对外参数
	theme: (state) => state.theme,
	network: (state) => state.network,
	deviceInfo: (state) => state.deviceInfo,
	appInfo: (state) => state.appInfo,
	isMiniApp: (state) => state.appInfo.isMiniApp,
	sysCapsule: (state) => state.sysCapsule,
	leftCapsule: (state) => state.leftCapsule,
	barHeight: (state) => state.barHeight,
	screen: (state) => state.screen,
	pageSafeArea: (state) => state.pageSafeArea,
}
const mutations = {
	setTheme(state, theme) {
		state.theme = theme || 'light'
		cache.set('theme', theme)
	},
	setNetwork(state, networkInfo) {
		state.network = networkInfo
	},
	systemInit(state) {
		uni.getSystemInfo({
			success: function(e) {
				console.log(e)
				const sysCapsule = {
					width: 0,
					height: 0,
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				}
				const leftCapsule = {
					height: 0,
					top: 0,
					left: 0,
					width: 0,
				}


				const deviceInfo = {
					id: e.deviceId,
					type: e.deviceType,
					brand: e.deviceBrand || '',
					model: e.deviceModel || '',
					orientation: e.deviceOrientation,
					pixel_ratio: e.devicePixelRatio,
					os_name: e.osName,
					os_version: e.osVersion,
					os_language: e.osLanguage || 'zh-CN',
				}

				const uniPlatform = e.uniPlatform || 'web'

				let client = ''
				let platform = ''

				if (uniPlatform.indexOf('mp-') == 0) {
					platform = 'miniapp'
				} else if (uniPlatform.indexOf('quickapp-') == 0) {
					platform = 'quickapp'
				} else {
					platform = uniPlatform
				}

				switch (uniPlatform) {
					case 'mp-weixin':
						client = 'wxapp'
						break;
					case 'mp-alipay':
						client = 'aliapp'
						break;
					case 'mp-baidu':
						client = 'bdapp'
						break;
					case 'mp-toutiao':
						client = 'ttapp'
						break;
					case 'mp-lark':
						client = 'fsapp'
						break;
					case 'mp-qq':
						client = 'qqapp'
						break;
					case 'mp-kuaishou':
						client = 'ksapp'
						break;
					case 'mp-jd':
						client = 'jdapp'
						break;
					case 'mp-360':
						client = '360app'
						break;
					case 'quickapp-webview':
					case 'quickapp-webview-union':
					case 'quickapp-webview-huawei':
						client = 'quickapp'
						break;
					case 'app':
						client = uniPlatform
						break;
					default:
						client = uniPlatform
						let ua = window.navigator.userAgent.toLowerCase();
						if (ua.match(/micromessenger/i) == 'micromessenger') {
							client = 'wechat'
							platform = 'mp'
						} else if (ua.match(/alipayclient/i) == 'alipayclient') {
							client = 'alipay'
							platform = 'mp'
						}

						break;
				}



				const appInfo = {
					name: e.appName,
					version: e.appVersion,
					versionCode: e.appVersionCode,
					isDev: uni.config.runMode === "dev",
					info: uni.config.runMode == "dev" ?
						`${e.appName}[测试版] ${e.appVersion}` : `${e.appName} ${e.appVersion}`,
					isMiniApp: false,
					client: client,
					platform: platform, // 平台：mp,app,quickapp,web,miniapp
				}


				const barHeight = {
					statusBar: e.statusBarHeight,
					titleBar: e.titleBarHeight || 0,
					navBar: e.windowTop || 0,
					tabBar: e.windowBottom,
					allBar: 0,
				}

				const pageSafeArea = JSON.parse(
					JSON.stringify(e.safeArea)
				)

				const screen = {
					widthFull: e.screenWidth,
					heightFull: e.screenHeight,
					width: e.windowWidth,
					height: e.windowHeight,
					top: e.windowTop,
					bottom: e.windowBottom,
				}

				// #ifndef MP

				appInfo.isMiniApp = false

				// 左侧胶囊
				leftCapsule.height = 32
				leftCapsule.top = e.statusBarHeight + 4
				leftCapsule.left = 7
				leftCapsule.height = 87

				// #endif
				// #ifdef MP

				appInfo.isMiniApp = true

				const Capsule = uni.getMenuButtonBoundingClientRect()

				sysCapsule.width = Capsule.width
				sysCapsule.height = Capsule.height
				sysCapsule.top = Capsule.top
				sysCapsule.bottom = Capsule.bottom
				sysCapsule.left = Capsule.left
				sysCapsule.right = Capsule.right


				// 小程序左侧胶囊
				leftCapsule.width = Capsule.width
				leftCapsule.height = Capsule.height - 2
				leftCapsule.top = Capsule.top
				leftCapsule.left = screen.widthFull - Capsule.right

				// #endif

				// 重新计算
				if (barHeight.statusBar == 0) barHeight.statusBar = pageSafeArea.top
				if (barHeight.tabBar == 0) barHeight.tabBar = screen.heightFull - pageSafeArea.bottom


				barHeight.allBar = barHeight.statusBar + barHeight.titleBar + barHeight.navBar

				// #ifndef MP
				barHeight.allBar = barHeight.allBar + 45

				// #endif
				// #ifdef MP

				barHeight.allBar = barHeight.allBar + 0

				// #endif


				// 回写
				state.deviceInfo = deviceInfo

				state.appInfo = appInfo
				state.sysCapsule = sysCapsule
				state.leftCapsule = leftCapsule

				state.barHeight = barHeight
				state.pageSafeArea = pageSafeArea

				state.screen = screen


				// console.log('screen', screen)
				// console.log('pageSafeArea', pageSafeArea)
				// console.log('barHeight', barHeight)

			},
		})
	},
	screenInit(state) {
		uni.getWindowInfo({
			success: function(e) {

				const barHeight = {
					statusBar: e.statusBarHeight,
					titleBar: e.titleBarHeight || 0,
					navBar: e.windowTop || 0,
					tabBar: e.windowBottom,
					allBar: 0,
				}

				const pageSafeArea = JSON.parse(
					JSON.stringify(e.safeArea)
				)

				const screen = {
					widthFull: e.screenWidth,
					heightFull: e.screenHeight,
					width: e.windowWidth,
					height: e.windowHeight,
					top: e.windowTop,
					bottom: e.windowBottom,
				}


				// 重新计算
				if (barHeight.statusBar == 0) barHeight.statusBar = pageSafeArea.top
				if (barHeight.tabBar == 0) barHeight.tabBar = screen.heightFull - pageSafeArea.bottom

				barHeight.allBar = barHeight.statusBar + barHeight.titleBar + barHeight.navBar

				// #ifndef MP
				barHeight.allBar = barHeight.allBar + 50

				// #endif
				// #ifdef MP

				barHeight.allBar = barHeight.allBar + 10

				// #endif

				state.barHeight = barHeight
				state.pageSafeArea = pageSafeArea

				state.screen = screen
			},
		})
	},
}

export default {
	state,
	getters,
	mutations,
}