import App from './App'

import config from '@/globalConfig.js';

import cache from "@/xinda/libs/cache";

import message from "@/xinda/libs/message";

import http from "@/xinda/libs/request";

import xcwl from "@/xinda/libs/xcwl";

// import uvUI from '@/uni_modules/uv-ui-tools'


import store from './store'

//全局组件配置
// uni.$fui = fuiConfig
//兼容nvue
uni.cache = cache;
uni.config = config;
uni.http = http;
uni.xcwl = xcwl;
uni.message = message

// #ifndef VUE3
import Vue from 'vue'

Vue.config.productionTip = false
// Vue.prototype.$store = store

Vue.prototype.$http = http

Vue.prototype.$message = message

// Vue.use(uvUI);

App.mpType = 'app'

const app = new Vue({
	store,
	...App
})
app.$mount()
// #endif

// #ifdef VUE3
import {
	createSSRApp,
} from 'vue';

/*
  在 Vue3 中，如果 nvue 使用了 Vuex 的相关 API，
  需要在 main.js 的 createApp 的返回值中 return 一下 Vuex
*/
import Vuex from "vuex";
export function createApp() {
	const app = createSSRApp(App)
	app.use(store)
	// app.use(uvUI);

	app.config.globalProperties.$http = http;
	app.config.globalProperties.$cache = cache;
	app.config.globalProperties.$message = message;

	app.config.globalProperties.$music = null
	return {
		Vuex,
		app
	}
}
// #endif